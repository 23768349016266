export default {
  bookingList: "รายการจอง",
  bookingHistory: "ประวัติการจอง",
  buttonStatus: "สถานะ",
  buttonEdit: "แก้ไข",
  buttonOther: "อื่น ๆ  ",
  bookingID: "หมายเลขการจอง",
  date: "วันที่",
  time: "เวลา",
  customerName: "ชื่อลูกค้า",
  packageType: "ประเภทแพ็กเกจ ",
  packageDetails: "รายละเอียดแพ็กเกจ",
  discountDetails: "Discount Details",
  partySize: "ขนาดของกลุ่ม",
  paidAmount: "จำนวนเงินที่ชำระแล้ว",
  specialRequest: "คำขอพิเศษ",
  action: "Action",
  orderStatus: "Order Status:",
  orderLater: "Order Later",
  buttonMenu: "Menu",
  seemore: "See More",
  seeless: "See Less",
  buttonView: "View",
  moreDetail: "รายละเอียดเพิ่มเติม",
  occasionTag: "แท็กโอกาส",
  driverDetails: "รายละเอียดคนขับ",
  noteToDriver: "Note To Driver",
  googleMapLink: "ลิงก์ Google Map",
  address: "Address",
  est: "Est",
  due: "จ่ายที่ร้าน",
  distance: "Distance",
  deliveryfee: "Delivery Fee",
  selfPickup: "ไปรับเอง",
  delivery: "จัดส่ง",
  noShow: "ไม่มา",
  times: "Times",
  markAsArrived: "ถึงแล้ว",
  cancel: "ยกเลิก",
  approve: "ยืนยัน",
  reject: "ปฏิเสธ",
  sendConfirmationToUser: "ส่งการยืนยันไปยังผู้จอง",
  sendCustomSMS: "ส่ง SMS ที่กำหนดเอง",
  Sendreviewlink: "ส่งลิงก์รีวิว",
  createABooking: "สร้างการจอง",
  bookingDetails: "รายละเอียดการจอง",
  tableName: "Table Name",
  tableNumber: "Table Number",
  status: "สถานะ",
  name: "ชื่อ",
  phone: "เบอร์โทร",
  adult: "ผู้ใหญ่",
  kids: "เด็ก",
  email: "อีเมล",
  notes: "Notes",
  paymentDetails: "Payment Details",
  paymentStatus: "สถานะการชำระเงิน",
  dueAmount: "Due amount",
  thb: "THB",
  packageName: "ชื่อแพ็กเกจ",
  totalPackagePrice: "ราคารวมของแพ็กเกจ",
  depositAmount: "Deposit amount",
  paymentMethods: "Payment Methods",
  promptPayQR: "PromptPay QR",
  debitCreditCard: "บัตรเดบิต/เครดิต",
  shopeePay: "Shopee Pay",
  trueMoneyWallet: "True Money Wallet",
  discountType: "Discount Type",
  totalPriceAfterDiscount: "Total price after discount",
  amount: "Amount",
  editBooking: "Edit Booking",
  seeReason: "ดูเหตุผล",
  addMorePacks: "เพิ่มแพ็กเกจ",
  filters: "ตัวกรองs",
  bookingStatus: "สถานะการจอง",
  customerPhone: "โทรศัพท์ลูกค้า ",
  startTime: "เวลาเริ่มต้น",
  serviceType: "ประเภท Service",
  specificPackage: "แพ็กเกจเฉพาะ",
  searchFilters: "ตัวกรองการค้นหา",
  rangeDate: "หลายวัน",
  singleDate: "วันเดียว",
  startDate: "วันที่เริ่มต้น",
  endDate: "วันที่สิ้นสุด",
  clearAll: "ลบทั้งหมด ",
  showResults: "แสดงผล",
  all: "ทั้งหมด",
  pendingArrival: "รอการมาถึง",
  arrived: "ถึงแล้ว",
  cancelled: "ยกเลิกแล้ว",
  hhPendingArrival: "H@H Pending Arrival",
  deliveryCompleted: "จัดส่งเรียบร้อย",
  orderReceived: "สั่งซื้อสำเร็จ",
  allYouCanEat: "All You Can Eat (AYCE)",
  partyPack: "Party Pack (PP)",
  hh: "H@H (HH)",
  xperience: "Xperience (XP)",
  dineIn: "ทานที่ร้าน",
  selfPickUp: "ไปรับเอง",
  noDeposit: "No deposit",
  hasDeposit: "Has deposit",
  prepaid: "ชำระล่วงหน้าแล้ว",
  export: "ดึงรายงาน",
  exportBookingList: "ดึงรายงานการจอง",
  createBooking: "สร้างการจอง",
  allBookings: "การจองทั้งหมด",
  pendingBookings: "การจองรอยืนยัน",
  todayBookings: "การจองวันนี้",
  arrive: "มาถึงแล้ว",
  pleaseSelect: "เลือกอย่างน้อยหนึ่งอย่าง",
  customSMS: "Custom SMS (160 characters)",
  booking: "การจอง",
  discount: "ส่วนลด",
  bookingDeatails: "รายละเอียดการจอง",
  createdAt: "สร้างเมื่อ:",
  createBy: "สร้างโดย:",
  cancelledBy: "ยกเลิกโดย:",
  modifiedBy: "แก้ไขโดย:",
  customerDetails: "รายละเอียดลูกค้า",
  phoneNo: "เบอร์โทร.",
  myRestaurantBooking: "การจองของลูกค้าที่ร้านนี้",
  allRestaurantsBooking: "การจองของลูกค้าที่ร้านทั้งหมด",
  paymentSummary: "สรุปการชำระเงิน",
  total: "ทั้งหมด",
  method: "ช่องทาง",
  driverId: "Driver ID",
  driverName: "Driver name",
  driverPhone: "Driver phone",
  drivingToRestaurant: "Driving  to restaurant",
  trackingLink: "Tracking Link",
  selectDate: "เลือกวันที่",
  voucher: "เวาเชอร์",
  pleaseChoose: "เลือกแพ็กเกจ",
  show: "แสดง",
  approveThisBooking: "ยืนยันการจองนี้",
  rejectThisBooking: "ปฏิเสธการจองนี้",
  back: "ย้อนกลับ",
  areYouSureApproveThisId:
    "คุณแน่ใจหรือไม่ว่าต้องการยืนยันหมายเลขการจอง '{0}' นี้?",
  areYouSureRejectThisId:
    "คุณแน่ใจหรือไม่ว่าต้องการปฏิเสธหมายเลขการจอง '{0}' นี้?",
  alertPendingBooking: `คุณมี {0} การจองรอการยืนยัน! กรุณากด "ยืนยัน" หรือ "ปฏิเสธ" การจองเหล่านี้`,
};
